import React from "react";

export default function Triangles() {
    return (
        <>
            <h2>A Brief Explanation of What is Happening</h2>
            <p>The triangles are slowly learning to seek out the green circle.
                The triangles each are using a super small neural network,
                but they actually learn through a genetic algorithm.
                <br></br>
                The graphs that appear after a few generations are the best performer of that generation, and the average.
            </p>

            <iframe className={"w-full aspect-square"}  src="/project-code/learningtriangles/code/index.html" />

            <h2>Some Take Aways</h2>
            <p>While this is a very fun thing to watch,
                it's a terribly inefficient form of AI.
                This problem would obviously be better handled by a decision tree,
                and if I really wanted to use a neural net, traditional training methods would have been faster.
                <br></br>
                That said, it was a lot of fun to build and watch, and helped me get a better grasp of genetic algorithms.
            </p>
        </>
    );
}