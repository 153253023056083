import React from "react";

export default function GoAI() {
    return (
        <>
            <h2>What I Was Trying to Make</h2>
            <p>
                This Go AI uses MCTS to play Go.
                I wanted to make something that could rank all possible moves,
                consider the rolling average quality of it's oponents decisions, and play at a similar skill level.
                Many of the avalible AIs are too strong to be good oponents for beginning players so I wanted
                something that could be a good learning tool for any ability level.
            </p>
            <h2>What I Made</h2>
            <p>
                Go is famously a hard game for AI to play, and I've now learned this the hard way,
                this AI is not difficult to beat. It plays at an extremely low level, even on small boards.
                On larger boards it's strategy gets even worse, mostly sticking to the corners
            </p>
            <iframe className={"w-full aspect-[5/6]"}  src="/project-code/goai/code/index.html" />
            <h2>How to Use It</h2>
            <p>
                You can click on any space to place a stone, or use the buttons to pass.
            </p>
        </>
    );
}