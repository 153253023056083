import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import LandingPage from './pages/LandingPage';
import ProjectsPage from './pages/ProjectsPage';
import AboutPage from "./pages/AboutPage";
import ContentPage from "./pages/ContentPage";
import Header from "./components/Header";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="projects">
                  <Route index element={<ProjectsPage />} />
                  <Route path=":contentURL" element={<ContentPage />} />
              </Route>
              {/*404 Error Route*/}
              <Route path="*" element={
                  <>
                      <Header />
                      <div className={"px-6 md:px-10 py-8 border-b border-black"}>
                          <h1 className={"text-4xl"}>Error: 404</h1>
                          <p>It doesn't look like that page exists</p>
                      </div>
                  </>
              }/>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);