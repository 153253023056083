import React from "react";
import HeaderLink from "./HeaderLink";

export default function Header() {
    return (
        <>
            <div className={"w-full text-white px-6 md:px-10 bg-black py-2 fixed border-b border-white"}>
                <HeaderLink to={"/"}>OSKARALYN</HeaderLink>
                <HeaderLink to={"/about"}>ABOUT</HeaderLink>
                <HeaderLink to={"/projects"}>PROJECTS</HeaderLink>
            </div>
            <div className={"h-[3.25rem]"} />
        </>

    );
}