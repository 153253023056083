import React from "react";

export function Figure({children, caption}) {
    return (
        <div className={"max-w-article m-auto flex"}>
            <div className={"m-auto"}>
                {children}
                <caption className={"block text-center text-sm text-gray-400"}>{caption}</caption>
            </div>
        </div>
    );
}