import React from "react";

export function LinkButton({children}) {
    return (
            <button className={"" +
                "pb-0.5 pt-1 border-b-4 " +
                "text-white border-white " +
                "hover:border-0 hover:mb-1 "
            }
            >{children}</button>
    );
}