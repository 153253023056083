import {NavLink} from "react-router-dom";
import React from "react";

export default function HeaderLink({children, to}) {
    return (
        <NavLink to={to} end key={to} className={({isActive}) =>
            "border-2 text-sm mr-1 px-1 py-0.5 text-heavy tracking-tight " +
            "hover:bg-white hover:bg-opacity-40 " +
            "active:bg-opacity-60 " +
            (isActive ? "border-b-white border-black" : "border-white")
        }
        >
            {children}
        </NavLink>
    );
}