import React from "react";

export default function DotTag() {
    return (
        <>
            <h2>A Brief Explanation of What is Happening</h2>
            <p>These dots are bouncing around randomly with some basic tag-like rules.
                They all start in the middle and are given a random direction.
                At timestep 200 all dots are assigned a random team, most dots will still be assigned white or neutral.
                Whenever two dots meet it is a tag, if a neutral dot is tagged by a team dot, it joins the team.
                If two dots from different teams tag they become neutral. Overtime one team is likely to take over the
                entire field.
            </p>

            <iframe className={"w-full aspect-video"}  src="/project-code/dottag/code/index.html" />

            <p>This system of 3d is something I started building out a long time ago, and that I discuss
                more in my <a className="link" href="/projects/graphics-in-vanilla-js">post about making 3d graphics in raw JS</a>
            </p>
        </>
    );
}