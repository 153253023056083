import React from "react";

export default function GravitySiumlation() {
    return (
        <>
            <h2>A Brief Explanation of What is Happening</h2>
            <p>
                Each of these particles is a point mass in a vacuum, they all exert a gravitational pull on each other.
                All of the starting positions are random, so it will take some time for the system to come to a stable
                state.
                There are some floating point issues, but otherwise the system conserves energy, and will continue to
                move permanently.
            </p>

            <iframe className={"w-full aspect-video"} src="/project-code/gravity/code/index.html" />

            <p>
                It won't always happen, but I find it really cool that this simple simulation will still get orbiting
                bodies occasionally.
                If you give it a few tries and look closely you might be able to find one.
            </p>

            <p>This system of 3d is something I started building out a long time ago, and that I discuss
                more in my <a className="link" href="/projects/graphics-in-vanilla-js">post about making 3d graphics in vanilla JS</a>
            </p>
        </>
    );
}