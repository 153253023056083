import {useParams} from "react-router-dom";
import {formatDate, getContentMetadataByURL} from "../contentMetadata";
import Header from "../components/Header";
import React from "react";

export default function ContentPage() {
    let params = useParams();
    let content = getContentMetadataByURL(params.contentURL);
    if (content !== undefined) {
        return (
            <>
                <Header />
                <div className={"p-10 w-full article"}>
                    <div className={"mb-8"}>
                        <h1 className={"text-4xl border-b-2 border-black"}>{content.name}</h1>
                        <p className={"text-xs text-gray-400 font-light"}>{formatDate(content.date, content.dateSpecificity)}</p>
                    </div>
                    {content.page}
                </div>
            </>
        );
    } else {
        // Page for a bad project link
        return (
            <>
                <Header />
                <p className={"p-10"}>
                    It doesn't look like project {params.contentId} exists.
                </p>
            </>
        );
    }
}

