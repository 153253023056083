import React from "react";
import {Figure} from "../../components/Figure";

export default function First3D() {
    return (
        <>
            <h2>A Brief Explanation of What is Happening</h2>
            <p>This was an attempt to visualize an in-progress procedural random function:</p>
            <code>
                // Park Miller Random <br/>
                function PMRng(seed) {"{"}<br/>
                seed += 1; <br />
                return ((Math.pow(7, 5) * seed) % (Math.pow(2, 31) - 1)) % (modNumber / (modMod)); <br />
                {"}"}
            </code>
            <p>The function is wrong but the sawtooth-like waveforms created here are more interesting than the fixed
                version.</p>

            <iframe  className={"w-full aspect-video"} src="/project-code/mapping3d/code/index.html" />

            <p className="caption">Note: these old projects do not rescale well, so may appear strangely on small
                screens</p>

            <iframe  className={"w-full aspect-video"} src="/project-code/mapping3d/code2/index.html" />

            <p>
                You can read more about this system in my <a className="link" href="/projects/graphics-in-vanilla-js">post about making
                3d graphics in raw JS</a>
            </p>
        </>
    );
}