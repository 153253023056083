import React from "react";

export default function Roguelike() {
    return (
        <>
            <iframe className={"w-full aspect-video"}  src="/project-code/roguelike/code/index.html" />

            <h2>How it Works</h2>
            <p>This uses a dungeon generation algorithm loosely based off of whats publicly available about diablo's algorithm.
            <ol className={"list-decimal pl-8 my-2"}>
                <li>It places a starting room</li>
                <li>It picks a random wall tile, checks if there is space for a random room type behind the wall, and build it if there is </li>
                <li>Repeat step two until the dungeon is big-ish</li>
                <li>Then it takes a decorating pass, adding extra doors, boxes, slimes, and the hole to the next level</li>
            </ol>
            </p>

            <h2>Content</h2>
            <p>The game has enough content for a few runs, including:
                <ul className={"list-disc pl-8 my-2"}>
                    <li>Leveling: Your character gets faster, more damage, and more health with each level</li>
                    <li>Meta Leveling: You gain one permanent experience point for every level up, floor you decend, and every floor you 100%</li>
                    <li>Items: There are two different healing items</li>
                    <li>Enemies: There are three enemies!</li>
                </ul>
            </p>
        </>
    );
}