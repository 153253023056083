import React from "react";
import {LinkButton} from "../../components/LinkButton";
import {Figure} from "../../components/Figure";

export default function JsEngine() {
    return (
        <>
            <h2>The Demo</h2>
            <Figure caption={"This gif shows a sample of the gameplay."}>
                <img src="/content/jsengine/clipped.gif" alt="space gif"/>
            </Figure>
            <p>
                Your ship can move around and fire at other ships, if you take too many hits you'll die. For now enemy
                and allied
                ships simply spawn in and will fight each other indefinitely. You can collect the credits dropped by the
                ships you destroy,
                but cannot yet spend them.
            </p>
            <p>
                A handful of different ships and factions were added and will be showcased in another sample level.
            </p>

            <h2>Controls</h2>
            <p> Use WASD to move, Space to Shoot </p>
            <p>You can try it <a href="https://oskar-alyn.github.io/Demo/" target="_blank">here</a>.</p>
        </>
    );
}