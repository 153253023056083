import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { getContentMetadata, formatDate, UNLISTED, SHOWCASE } from "../contentMetadata";
import { LinkButton } from "../components/LinkButton";
import HeaderLink from "../components/HeaderLink";

export default function ProjectsPage() {
    let contentMetadata = getContentMetadata();
    contentMetadata.sort((a, b) => b.date - a.date);
    return (
        <div className={"h-screen w-screen"}>
            <div className={"bg-black h-[52px] w-screen fixed border-b border-white"} />
            <div className={"w-screen h-1/2 bg-white p-2 pb-1 z-20 relative"}>
                <div className={"flex showcase-bg w-full h-full z-20 relative"}>
                    <div className={"text-white flex fixed py-1 px-4 z-20"}>
                        <HeaderLink to={"/"}> OSKARALYN</HeaderLink>
                        <HeaderLink to={"/about"}> ABOUT</HeaderLink>
                        <HeaderLink to={"/projects"}> PROJECTS</HeaderLink>
                    </div>
                    <div  className={"m-auto w-full text-white p-12 text-center"}>
                        <h1 className={"w-full border-b-2 border-white text-4xl tracking-tight text-u mb-0.5"}>Project Showcase</h1>
                        <p className={"m-auto max-w-article"}>
                            Some of my favorite projects and articles so far
                        </p>
                    </div>
                </div>
            </div>
            <div className={"z-0"}>
                <div className={"lg:grid grid-cols-2 p-1 pt-0"}>
                    {
                        contentMetadata.map(content => (
                            content.visibility === SHOWCASE ?
                                <div className={"p-1 w-full h-full bg-white"} key={content.url}>
                                <div
                                className={"w-full h-full sm:flex flex-row-reverse showcase-bg"}
                                >
                                <img
                                src={"thumbnails/" + content.thumbnail}
                                className={"aspect-square w-full sm:w-1/2 object-cover flex-1"}
                                alt={"thumbnail"}
                                />
                                <div className={"bg-black flex-1"}>
                                <div className={"xl:flex h-full"}>
                                <div className={"text-white p-4 m-auto w-full"}>
                                <h2 className={"text-2xl"}>{content.name}</h2>
                                <p className={"text-xs text-gray-400 font-light"}>{formatDate(content.date, content.dateSpecificity)}</p>
                                <p className={"py-2 max-w-article"}>{content.description}</p>
                                <Link to={`/projects/${content.url}`} >
                                <LinkButton>Read More</LinkButton>
                                </Link>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                :
                                <></>
                        ))
                    }
                    <div className={"p-1 w-full h-full bg-white hidden lg:block lg:odd:hidden"}>
                        <div
                            className={"w-full h-full sm:flex flex-row-reverse showcase-bg"}
                        >
                            <div className={"bg-black bg-opacity-50 flex-1 flex"}>
                                    <div className={"text-white p-4 m-auto"}>
                                        <p className={"py-2 max-w-article"}>More coming soon!</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}