import React from "react";

export default function LandingPage() {
    return (
        <div className={"w-screen h-screen p-2 bg-white"}>
            <div className={"flex showcase-bg w-full h-full"}>
                <div  className={"m-auto w-full text-white p-12 text-center"}>
                    <h1 className={"w-full border-b-2 border-white text-4xl tracking-tight text-u mb-0.5"}>OSKAR ALYN</h1>
                    <a href={"about"}><button className={
                        "border-2 border-white text-sm m-1 px-1 py-0.5 text-heavy tracking-tight " +
                        "hover:bg-white hover:bg-opacity-40 " +
                        "active:bg-opacity-60"
                    } >ABOUT</button></a>
                    <a href={"projects"}><button className={
                        "border-2 border-white text-sm m-1 px-1 py-0.5 text-heavy tracking-tight " +
                        "hover:bg-white hover:bg-opacity-40 " +
                        "active:bg-opacity-60"
                    } >PROJECTS</button></a>
                </div>
            </div>
        </div>
    );
}