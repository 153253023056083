// pages
import VanillaJsGraphics from "./pages/content-pages/VanillaJsGraphics";
import JsEngine from "./pages/content-pages/JsEngine";
import DotTag from "./pages/content-pages/DotTag";
import GravitySiumlation from "./pages/content-pages/GravitySiumlation";
import First3D from "./pages/content-pages/First3D";
import GoAI from "./pages/content-pages/GoAI";
import Triangles from "./pages/content-pages/Triangles";
import Roguelike from "./pages/content-pages/Roguelike";

// visibilities
export const SHOWCASE = "SHOWCASE";
export const STANDARD = "STANDARD";
export const UNLISTED = "UNLISTED";

// types
export const ARTICLE = "ARTICLE";
export const CODE_DOODLE = "CODE_DOODLE";
export const GAME = "GAME";

// date specificity
export const YEAR = "YEAR";
export const MONTH = "MONTH";
export const DAY = "DAY";

let contentMetadata = [
    {
        name: "3D Graphics in Vanilla JS",
        visibility: SHOWCASE,
        type: ARTICLE,
        url: "graphics-in-vanilla-js",
        thumbnail: "js-graphics-article.png",
        page: <VanillaJsGraphics />,

        date: 1629313479000,
        dateSpecificity: DAY,

        description:
            "An explanation of a particular style of 3d, " +
            "how I came to use it, how it works, and some of the most interesting examples. ",
    },
    {
        name: "Vanilla JS Game Engine Demo",
        visibility: SHOWCASE,
        type: GAME,
        url: "js-game-engine-demo",
        thumbnail: "engine-demo.png",
        page: <JsEngine />,

        date: 1580364000000,
        dateSpecificity: DAY,

        description:
            "One of my largest projects featuring, a custom 3d graphics system, " +
            "clean keyboard input tracking, handling of game state, and simple AIs.",
    },
    {
        name: "Gravity Simulation",
        visibility: STANDARD,
        type: CODE_DOODLE,
        url: "gravity-simulation",
        thumbnail: "gravity.png",
        page: <GravitySiumlation />,

        date: 1549000800000,
        dateSpecificity: MONTH,

        description: "A simulation of a large number of dots behaving as point masses in a vacuum.",
    },
    {
        name: "3D Dot Tag",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "dot-tag",
        thumbnail: "dottag.png",
        page: <DotTag />,

        date: 1535778000000+12,
        dateSpecificity: MONTH,

        description: "Simple example of 3d where some dots are playing a game of tag",
    },
    {
        name: "First Attempt at 3D",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "first-attempt-at-3d",
        thumbnail: "first3d.png",
        page: <First3D />,

        date: 1535778000000,
        dateSpecificity: MONTH,

        description: "This is the very first 3d thing I ever built.",
    },
    {
        name: "Agar.io Style Simulation",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "agar-like",
        thumbnail: "agarlike.png",
        page: <></>,

        date: 1504242000000,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Attrition Calculator",
        visibility: STANDARD,
        type: CODE_DOODLE,
        url: "attrition",
        thumbnail: "attrition.png",
        page: <></>,

        date: 1490072400000,
        dateSpecificity: DAY,

        description: "The attrition sim is designed to show the way that numerical advantages or differences can make a difference " +
            "in the outcome of a conflict. ",
    },
    {
        name: "First Attempt at Dungeon Generation",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "first-dungeon-generation",
        thumbnail: "sea-dungeon.png",
        page: <></>,

        date: 1492837200000 + 2,
        dateSpecificity: YEAR,

        description: "In this project rooms are randomly generated. The biggest problem here was making the edges look right. I also tried some lighting effects.",
    },
    {
        name: "First Ecosystem Simulation",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "first-ecosystem",
        thumbnail: "first-ecosystem.png",
        page: <></>,

        date: 1489467600000,
        dateSpecificity: DAY,

        description: "Originally this was supposed to be an evolution simulator, " +
            "but now it simulates ecosystems. Especially cyclical populations!",
    },
    {
        name: "Evolution Simulator",
        visibility: SHOWCASE + "HIDE",
        type: CODE_DOODLE,
        url: "evolution-sim",
        thumbnail: "evolutionsim.png",
        page: <></>,

        date: 1535778000000+10,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Forest Village Game",
        visibility: STANDARD,
        type: GAME,
        url: "forest-village",
        thumbnail: "forestvillage.png",
        page: <></>,

        date: 1492837200000 + 4,
        dateSpecificity: YEAR,

        description: "In this game I have a forest ecosystem with deer, wolves, and trees. I also " +
            "added a human village with lumberjacks, hunters, guards, peasants and the player.",
    },
    {
        name: "Dynamic Go Playing AI",
        visibility: SHOWCASE,
        type: CODE_DOODLE,
        url: "go-ai",
        thumbnail: "goai.png",
        page: <GoAI />,

        date: 1608530400000,
        dateSpecificity: "",

        description: "An AI that attempts to match your skill in Go.",
    },
    {
        name: "Goblin Game",
        visibility: STANDARD,
        type: GAME,
        url: "goblins",
        thumbnail: "goblins.png",
        page: <></>,

        date: 1492837200000 + 4,
        dateSpecificity: YEAR,

        description:
            "You can lead parties of goblins out " +
            "into the woods to hunt pigs. By moving over to the food tent you can check how " +
            "much you have. The biggest challenge in this project was the biome generation.",
    },
    {
        name: "Hexagonal Terrain Generation",
        visibility: STANDARD,
        type: CODE_DOODLE,
        url: "hex-terrain",
        thumbnail: "hexterrain.png",
        page: <></>,

        date: 1492837200000 + 1,
        dateSpecificity: YEAR,

        description: "",
    },
    {
        name: "Genetic Neural Triangles",
        visibility: SHOWCASE,
        type: CODE_DOODLE,
        url: "triangles",
        thumbnail: "triangles.png",
        page: <Triangles />,

        date: 1549000800000+2,
        dateSpecificity: MONTH,

        description: "A visualizer for triangles slowly learning to seek out a circle, " +
            "using a genetic algorithm so train a neural net. " +
            "Slow for AI, but great to watch. ",
    },
    {
        name: "Third Attempt at 3D",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "third3d",
        thumbnail: "third3d.png",
        page: <></>,

        date: 1535778000000+2,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Fourth Attempt at 3D",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "fourth3d",
        thumbnail: "fourth3d.png",
        page: <></>,

        date: 1535778000000+2,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Necromancy Game",
        visibility: STANDARD,
        type: GAME,
        url: "necromancy-game",
        thumbnail: "necromancy.png",
        page: <></>,

        date: 1492837200000 + 5,
        dateSpecificity: YEAR,

        description: "",
    },
    {
        name: "An Early Attempt at a Neural Network",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "neural-color",
        thumbnail: "neural-color.png",
        page: <></>,

        date: 1489640400000,
        dateSpecificity: DAY,

        description: "My first attempt at machine learning. Backpropagation " +
            "is the bigest issue here but technically it does learn.",
    },
    {
        name: "Neural Network Addition",
        visibility: SHOWCASE + "HIDE",
        type: CODE_DOODLE,
        url: "neural-addition",
        thumbnail: "neural-addition.png",
        page: <></>,

        date: 1549000800000+1,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Roguelike Dungeon Crawler",
        visibility: SHOWCASE,
        type: GAME,
        url: "roguelike",
        thumbnail: "roguelike.png",
        page: <Roguelike />,

        date: 1492837200000 + 10,
        dateSpecificity: YEAR,

        description: "A simple roguelike game, complete with items, enemies, and dungeon generation!",
    },
    {
        name: "RPG Inventory System",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "rpg-menu",
        thumbnail: "rpgmenu.png",
        page: <></>,

        date: 1492837200000 + 8,
        dateSpecificity: YEAR,

        description: "",
    },
    {
        name: "Cave Game Graphics",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "cave-game-graphics",
        thumbnail: "ruina.png",
        page: <></>,

        date: 1492837200000 + 7,
        dateSpecificity: YEAR,

        description: "",
    },
    {
        name: "3D Controllable Dots",
        visibility: STANDARD,
        type: CODE_DOODLE,
        url: "ships-3d",
        thumbnail: "ships-3d.png",
        page: <></>,

        date: 1535778000000+13,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Space Hero Partial Game",
        visibility: UNLISTED,
        type: GAME,
        url: "space-hero-attempt",
        thumbnail: "space-hero-attempt.png",
        page: <></>,

        date: 1492837200000+1,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Spatium Tactics",
        visibility: STANDARD,
        type: GAME,
        url: "spatium-tactics",
        thumbnail: "spatium-tactics.png",
        page: <></>,

        date: 1492837200000,
        dateSpecificity: DAY,

        description: "A strategy game about formations of space ships",
    },
    {
        name: "Square Reaper",
        visibility: STANDARD,
        type: GAME,
        url: "square-reaper",
        thumbnail: "square-reaper.png",
        page: <></>,

        date: 1480140000000,
        dateSpecificity: DAY,

        description:
            "Play as the Grim Reaper against the world! You can buy upgrades if you " +
            "  collect enough bones to buy them at shops, and then fight off different " +
            "  types of enemies.",
    },
    {
        name: "3D Terrain Generation",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "terrain3d",
        thumbnail: "terrain3d.png",
        page: <></>,

        date: 1535778000000+11,
        dateSpecificity: MONTH,

        description: "",
    },
    {
        name: "Time Stop Combat",
        visibility: UNLISTED,
        type: CODE_DOODLE,
        url: "time-combat",
        thumbnail: "time-combat.png",
        page: <></>,

        date: 1492837200000 + 6,
        dateSpecificity: YEAR,

        description: "",
    },
    {
        name: "Zombie Necromancy Game",
        visibility: SHOWCASE + "HIDE",
        type: GAME,
        url: "zombies",
        thumbnail: "zombies.png",
        page: <></>,

        date: 1492837200000 + 3,
        dateSpecificity: YEAR,

        description:
            "This project was a sort of prototype for my game NecroSiege. It was also " +
            "  one of the first AI's I wrote that utilizes multiples different behaviour states.",
    },
];

export function getContentMetadata() {
    return contentMetadata;
}

export function getContentMetadataByURL(url) {
    return contentMetadata.find(
        content => content.url === url
    );
}

export function formatDate(date, specificity) {
    let dateType = new Date(date);

    if (specificity === YEAR) {
        return dateType.getFullYear();
    } else if (specificity === MONTH) {
        return months[dateType.getMonth()] + " " + dateType.getFullYear();
    } else {
        let suffix = "th"
        let day = dateType.getDay();
        if (day % 10 === 1) { suffix = "st"; }
        if (day % 10 === 2) { suffix = "nd"; }
        if (day % 10 === 3) { suffix = "rd"; }
        return months[dateType.getMonth()] + " " + dateType.getDay() + suffix + ", " + dateType.getFullYear();
    }
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]