import React from "react";
import Header from "../components/Header";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function AboutPage() {
    return (
        <div className={"w-screen h-screen p-2 bg-white"}>
            <div className={"md:flex flex-row-reverse showcase-bg w-full h-full"}>
                <div  className={"m-auto md:w-1/2 text-white p-12"}>
                    <a href={"/"}> <h1 className={"w-full border-b-2 border-white text-4xl tracking-tight text-right mb-0.5"}>OSKAR ALYN</h1></a>
                    <div className={"text-right"}>
                        <a href={"/"}><button className={
                            "border-b-2 border-white text-sm m-1 px-1 py-0.5 text-heavy tracking-tight " +
                            "hover:bg-white hover:bg-opacity-40 " +
                            "active:bg-opacity-60"
                        } >ABOUT</button></a>
                        <a href={"projects"}><button className={
                            "border-2 border-white text-sm m-1 px-1 py-0.5 text-heavy tracking-tight " +
                            "hover:bg-white hover:bg-opacity-40 " +
                            "active:bg-opacity-60"
                        } >PROJECTS</button></a>
                    </div>
                </div>
                <div  className={"m-auto md:w-1/2 text-white p-12"}>
                    <div className={"w-fit m-auto lg:m-0 "}>
                        <div className={"max-w-article"}>
                            <h1 className={"text-4xl"}>Hi, I'm Oskar Alyn!</h1>
                            <p className={"my-4"}>
                                I'm a software engineer with professional experience in web-development,
                                academic experience in graphics, and personal experience in game development.
                                I love making new things, and I love making code faster, more readable, and more robust.
                            </p>
                            <p className={"my-4"}>

                            </p>
                            <div className={"mt-8"}>
                                <a href={"https://github.com/Oskar-Alyn"} target={"_blank"}>
                                    <GitHubIcon className={"m-2 p-1 ml-0 border-2 border-white hover:bg-white hover:bg-opacity-25"} fontSize={"large"} />
                                </a>
                                <a href={"https://www.instagram.com/oskar_alyn/"} target={"_blank"}>
                                    <InstagramIcon className={"m-2 p-1 border-2 border-white hover:bg-white hover:bg-opacity-25"} fontSize={"large"} />
                                </a>
                                <a href={"https://www.linkedin.com/in/oskaralyn/"} target={"_blank"}>
                                    <LinkedInIcon className={"m-2 p-1 border-2 border-white hover:bg-white hover:bg-opacity-25"} fontSize={"large"} />
                                </a>
                                <a href={"mailto:oskaralyn@gmail.com"} target={"_blank"}>
                                    <EmailIcon className={"m-2 p-1 border-2 border-white hover:bg-white hover:bg-opacity-25"} fontSize={"large"} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}